import { Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logisticCompanyImage from '../../assets/images/logistic_company.png'
import airwaysImage from '../../assets/images/airways.png'
import { useTranslation } from 'react-i18next'
import Card from '../../components/card'

const Logistic = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const items = [
        {
            id: null,
            title: t('logistic_companies'),
            image: logisticCompanyImage,
            color: 'green',
            link: 'logistic-companies'
        },
        {
            id: null,
            title: t('uzairways'),
            image: airwaysImage,
            color: 'green',
            link: 'uzairways',
        }
    ]

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('logistics')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {items?.length} {t('ta')}</span>
            </div>

            <Row gutter={[24, 24]}>
                {
                    items?.map((item, index) => {
                        return (
                            <Card key={index} item={item} url={item?.url} />
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default Logistic