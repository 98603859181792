import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Empty, Input, Row } from 'antd'
import Loader from '../loader'
import Error from '../error'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import http from '../../utils/helpers'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const ForeignBrands = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [search, setSearch] = useState(null)

    const filter = useMemo(() => {
        return {
            search
        }
    }, [search])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['brand-company', filter],
        queryFn: async () => {
            return await http.get(`export/brand/company/country/`, {
                params: filter,
            })
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('brand_company')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.length} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={12} xs={12} key={index}>
                                            <div
                                                className='custom-card p-4 py-8 h-[100%] flex items-center flex-col cursor-pointer'
                                                onClick={() => {
                                                    navigate(`inner-foreign-brands/${item?.id}?${queryString.stringify({ title: item?.name })}`)
                                                }}>
                                                <img src={item?.flag} alt={item?.name} className='xl:w-[100px] lg:w-[90px] md:w-[80px] w-[60px] mb-4' />
                                                <h3 className='m-0 text-center mt-auto'>{item?.name}</h3>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.length === 0 ? <Empty /> : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default ForeignBrands