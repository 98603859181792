import { Divider, Row } from 'antd'
import React from 'react'
import Card from '../../components/card'
import davlatlarImage from "../../assets/images/davlatlar.png"
import mahsulotlarImage from "../../assets/images/mahsulot.png"
import eksportMaydoniImage from "../../assets/images/b2b.png"
import narxlarImage from "../../assets/images/fruits.png"
import qadoqlashKorxonalariImage from "../../assets/images/qadoqlash.png"
import qadoqlashMahsulotlariImage from "../../assets/images/qadoqlash_vosita.png"
import eksportchiKorxonlarImage from "../../assets/images/export_transportation.png"
import logisticImage from "../../assets/images/logistics.png"
import salesAgentsImage from "../../assets/images/sales_agents.png"
import Cookies from 'js-cookie'
import foreignImporterImage from "../../assets/images/foreign_importer.png"
import successfulCompanyImage from "../../assets/images/successful_company.png"
import brnadCompanyImage from "../../assets/images/brand_company.png"
import daysImageUz from "../../assets/images/20_uz.png"
import daysImageUzb from "../../assets/images/20_uzb.png"
import daysImageRu from "../../assets/images/20_ru.png"
import daysImageEn from "../../assets/images/20_en.png"
import wareHousemage from "../../assets/images/warehouse-1024.png"
import assemblyWorker from "../../assets/images/foreign-technologs.png"
import { useTranslation } from 'react-i18next'

const Eksport = () => {
    const { t } = useTranslation()
    const initialLang = Cookies.get('i18next')

    const switchImg = () => {
        switch (initialLang) {
            case 'uz':
                return daysImageUz
            case 'ru':
                return daysImageRu
            case 'en':
                return daysImageEn
            case 'uzb':
                return daysImageUzb
            default:
                return daysImageUz
        }
    }

    const eksport = [
        {
            id: null,
            title: t('countries'),
            image: davlatlarImage,
            color: 'blue',
            link: 'export-countries'
        },
        {
            id: null,
            title: t('products'),
            image: mahsulotlarImage,
            color: 'blue',
            link: 'mahsulotlar'
        },
        {
            id: null,
            title: t('texnolog'),
            image: assemblyWorker,
            color: 'blue',
            link: 'foreign-technologs'
        },
        {
            id: null,
            title: t('brand_company'),
            image: successfulCompanyImage,
            color: 'blue',
            link: 'foreign-brands'
        },
        {
            id: null,
            title: t('successful_company'),
            image: brnadCompanyImage,
            color: 'blue',
            link: 'shopping-centers'
        },
        {
            id: null,
            title: t('b2b'),
            image: eksportMaydoniImage,
            color: 'blue',
            url: 'https://akk.karantin.uz/api/v2/common/url/asia_export/'
        },
        {
            id: null,
            title: t('fruits'),
            image: narxlarImage,
            color: 'blue',
            link: 'prices',
        },
        {
            id: null,
            title: t('packaging_companies'),
            image: qadoqlashKorxonalariImage,
            color: 'blue',
            link: 'qadoqlash-korxonalar'
        },
        {
            id: null,
            title: t('packaging_materials'),
            image: qadoqlashMahsulotlariImage,
            color: 'blue',
            link: 'qadoqlash-mahsulotlar'
        },
        {
            id: null,
            title: t('exporter_company'),
            image: eksportchiKorxonlarImage,
            color: 'blue',
            link: 'export-companies'
        },
        {
            id: null,
            title: t('logistics'),
            image: logisticImage,
            color: 'blue',
            link: 'logistic'
        },
        {
            id: null,
            title: t('sales_agents'),
            image: salesAgentsImage,
            color: 'blue',
            link: 'sales-agents'
        },
        {
            id: null,
            title: t('crops_area'),
            image: wareHousemage,
            color: 'blue',
            url: 'https://karantin.uz/uploads/menu/yetishtirilgan_ekin_maydonlari.xlsx'
        },
        {
            id: null,
            title: t('expected_harvest'),
            image: switchImg(),
            color: 'blue',
            url: 'https://karantin.uz/uploads/menu/crop.xlsx'
        },
        {
            id: null,
            title: t('foreign_importers'),
            image: foreignImporterImage,
            color: 'blue',
            link: 'foreign-importers'
        },
    ]

    return (
        <>
            <h1 className='mb-5'>{t('export')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    eksport?.map((item, index) => {
                        return <Card key={index} item={item} url={item?.url} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default Eksport