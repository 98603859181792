import React, { useEffect, useState } from 'react'
import Home from './pages/home'
import "./App.css"
import { Navigate, Route, Routes } from 'react-router-dom'
import FirstInnerPage from './pages/first-inner-page'
import NotFound from './pages/not-found'
import SecondInnerPage from './pages/second-inner-page'
import ThirdInnerPage from './pages/third-inner-page'
import Ipm from './pages/ipm'
import Biolabs from './pages/biolabs'
import Clinics from './pages/clinics'
import MineralOgitOmboriShtabTarkib from './pages/mineral-ombor-shtab'
import TuproqTahlilLab from './pages/tuproq-tahlil-lab'
import FeromonTutqich from './pages/feromon-tutqich'
import Minerals from './pages/minerals'
import Header from './components/header'
import Inspektor from './pages/inspektor'
import Seeds from './pages/seeds'
import InnerSeed from './pages/inner-seed'
import Kochat from './pages/kochat'
import InnerKochat from './pages/inner-kochat'
import Mezonlar from './pages/mezonlar'
import Library from './pages/library'
import InnerLibrary from './pages/inner-library'
import UsefulVideos from './pages/useful-videos'
import InnerVideo from './pages/inner-video'
import SingleWindowVideos from './pages/single-window-videos'
import InnerSingleWindowVideo from './pages/inner-single-window-video'
import ExportCountries from './pages/export-countries'
import InnerCountry from './pages/inner-country'
import InnerExportProduct from './pages/inner-export-product'
import QadoqlashKorxonalar from './pages/qadoqlash-korxonalar'
import QadoqlashMahsulotlar from './pages/qadoqlash-mahsulotlar'
import ExportCompanies from './pages/export-companies'
import ZoneCountries from './pages/zone-countries'
import InnerZoneCountry from './pages/inner-zone-country'
import InnerZoneCountryDetail from './pages/inner-zone-country-detail'
import Mahsulotlar from './pages/mahsulotlar'
import InnerMahsulotlar from './pages/inner-mahsulotlar'
import InnerProductDetail from './pages/detail-mahsulot'
import NewsById from './pages/news-by-id'
import AllNews from './pages/all-news'
import CallInspector from './pages/call-inspector'
import CheckSertificate from './pages/check-sertificate'
import Documents from './pages/documents'
import SesLaboratory from './pages/ses-laboratory'
import Prices from './pages/prices'
import AbroadPrices from './pages/abroad-prices'
import InnerAbroadPrice from './pages/inner-abroad-price'
import InnerAbroadPriceDetail from './pages/inner-abroad-price-detail'
import Logistic from './pages/logistic'
import LogisticCompanies from './pages/logistic-companies'
import InnerLogisticCompanies from './pages/inner-logistic-companies'
import Uzairways from './pages/uzairways'
import SalesAgents from './pages/sales-agents'
import InnerSalesAgents from './pages/inner-sales-agents'
import ForeignImporters from './pages/foreign-importers'
import InnerForeignImportersAgents from './pages/inner-foreign-impoters'
import Iso from './pages/iso'
import ForeignBrands from './pages/foreign-brands'
import InnerForeignBrands from './pages/inner-foreign-brands'
import ShoppingCenters from './pages/shopping-centers'
import InnerShoppingCenters from './pages/inner-shopping-centers'
import ForeignTechnologs from './pages/foreign-technologs'
import InnerForeignTechnologs from './pages/inner-foreign-technologs'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import http from './utils/helpers'
import { userLogin } from './reducers/authSlice'
import Loader from './pages/loader'
import Error from './pages/error'
import { useMutation } from '@tanstack/react-query'
import Aos from 'aos'
import TaqiqlanganModdalar from './pages/taqiqlangan-moddalar'
import Footer from './components/footer'

const App = () => {
  const dispatch = useDispatch()

  const accessToken = Cookies.get("access_token") ?? null
  const [hasAccessToken, setHasAccessToken] = useState(!!accessToken)

  const { mutate: verifyAuth, data, isError, isPending } = useMutation({
    mutationKey: ['auth-verify'],
    mutationFn: async () => {
      return await http.post(`/auth/sms/verify/`, {
        phone_number: '+998777777777',
        code: "123456",
        login_type: 'phone_number',
        email: null
      })
    },
    onSuccess: () => {
      setHasAccessToken(true)
    }
  })

  if (data) {
    dispatch(userLogin(data))
  }

  if (isError) {
    <Error />
  }

  useEffect(() => {
    Aos.init({
      duration: 500,
    });
  }, []);

  useEffect(() => {
    if (!hasAccessToken) verifyAuth()
  }, [hasAccessToken, verifyAuth])

  if (!hasAccessToken) {
    if (isPending) return <Loader />
    else if (isError) return <Error />
  } else
    return (
      <>
        <Header />
        <div className='container mx-auto py-3'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/products/:id' element={<FirstInnerPage />} />
            <Route path='/product/details/:id' element={<SecondInnerPage />} />
            <Route path='/product/detail/:id' element={<ThirdInnerPage />} />
            <Route path='/ipm/' element={<Ipm />} />
            <Route path='/biolabs/' element={<Biolabs />} />
            <Route path='/iso/' element={<Iso />} />
            <Route path='/clinics/' element={<Clinics />} />
            <Route path='/ses-laboratory/' element={<SesLaboratory />} />
            <Route path='/mineral-ogit-ombor/' element={<MineralOgitOmboriShtabTarkib />} />
            <Route path='/tuproq-tahlil-lab/' element={<TuproqTahlilLab />} />
            <Route path='/feromon-tutqich/' element={<FeromonTutqich />} />
            <Route path='/minerals/' element={<Minerals />} />
            <Route path='/inspektor/' element={<Inspektor />} />
            <Route path='/seeds/' element={<Seeds />} />
            <Route path='/seeds/:type' element={<InnerSeed />} />
            <Route path='/kochat/' element={<Kochat />} />
            <Route path='/kochat/:type' element={<InnerKochat />} />
            <Route path='/taqiqlangan-cheklangan-faol-moddalar' element={<TaqiqlanganModdalar />} />
            <Route path='/taqiqlangan-cheklangan-faol-moddalar/mezonlar' element={<Mezonlar />} />
            <Route path='/library' element={<Library />} />
            <Route path='/library/books' element={<InnerLibrary />} />
            <Route path='/useful-videos' element={<UsefulVideos />} />
            <Route path='/useful-videos/video' element={<InnerVideo />} />
            <Route path='/single-window-videos' element={<SingleWindowVideos />} />
            <Route path='/single-window-videos/video' element={<InnerSingleWindowVideo />} />
            <Route path='/export-countries' element={<ExportCountries />} />
            <Route path='/export-countries/details/:countryId' element={<InnerCountry />} />
            <Route path='/export-countries/details/:countryId/product/:productId' element={<InnerExportProduct />} />
            <Route path='/qadoqlash-korxonalar' element={<QadoqlashKorxonalar />} />
            <Route path='/qadoqlash-mahsulotlar' element={<QadoqlashMahsulotlar />} />
            <Route path='/export-companies' element={<ExportCompanies />} />
            <Route path='/zone-countries' element={<ZoneCountries />} />
            <Route path='/zone-countries/country/:countryId' element={<InnerZoneCountry />} />
            <Route path='/zone-countries/country/:countryId/detail/:id' element={<InnerZoneCountryDetail />} />
            <Route path='/mahsulotlar' element={<Mahsulotlar />} />
            <Route path='/mahsulotlar/:id' element={<InnerMahsulotlar />} />
            <Route path='/mahsulotlar/:id/detail/:productId' element={<InnerProductDetail />} />
            <Route path='/prices' element={<Prices />} />
            <Route path='/abroad-prices' element={<AbroadPrices />} />
            <Route path='/abroad-prices/inner-abroad-price/:countryId' element={<InnerAbroadPrice />} />
            <Route path='/abroad-prices/inner-abroad-price/:countryId/details/:productId' element={<InnerAbroadPriceDetail />} />
            <Route path='/call-inspektor' element={<CallInspector />} />
            <Route path='/check-sertificate' element={<CheckSertificate />} />
            <Route path='/documents' element={<Documents />} />
            <Route path='/news/:id' element={<NewsById />} />
            <Route path='/news' element={<AllNews />} />
            <Route path='/logistic' element={<Logistic />} />
            <Route path='/logistic-companies' element={<LogisticCompanies />} />
            <Route path='/logistic-companies/inner-logistic-company/:countryId' element={<InnerLogisticCompanies />} />
            <Route path='/uzairways' element={<Uzairways />} />
            <Route path='/sales-agents' element={<SalesAgents />} />
            <Route path='/foreign-brands' element={<ForeignBrands />} />
            <Route path='/foreign-technologs' element={<ForeignTechnologs />} />
            <Route path='/foreign-importers' element={<ForeignImporters />} />
            <Route path='/shopping-centers' element={<ShoppingCenters />} />
            <Route path='/sales-agents/inner-sales-agents/:countryId' element={<InnerSalesAgents />} />
            <Route path='/foreign-brands/inner-foreign-brands/:countryId' element={<InnerForeignBrands />} />
            <Route path='/foreign-technologs/inner-foreign-technologs/:countryId' element={<InnerForeignTechnologs />} />
            <Route path='/foreign-importers/inner-foreign-importers/:countryId' element={<InnerForeignImportersAgents />} />
            <Route path='/shopping-centers/inner-shopping-centers/:countryId' element={<InnerShoppingCenters />} />

            <Route path='/not-found' element={<NotFound />} />
            <Route path='*' element={<Navigate to='/not-found' />} />
          </Routes>
        </div>
        <Footer />
      </>
    )
}

export default App